%breadcrumb--default-font
  @include default-font(var(--breadcrumb-font-color), var(--breadcrumb-font-size), bold)

#breadcrumb,
.op-wp-breadcrumb
  margin-top: 10px
  height: initial
  display: none
  @include default-transition
  height: var(--breadcrumb-height)
  overflow: hidden
  background: none repeat scroll 0 0
  background-color: var(--body-background)
  border: none
  width: auto
  position: relative
  &.nosidebar
    // 7px of the icon to align breadcrumb with content
    margin-left: -7px
    ul
      margin: 0

  a
    font-size: 12px
    color: var(--content-link-color)
    font-weight: normal

  ul.op-breadcrumb
    height: initial
    li
        float: left
        margin: 0 5px 0 0
        padding: 0
        line-height: 20px 

  .op-wp-breadcrumb--ellipsed
    max-width: 420px
    @include text-shortener

  li
    @extend %breadcrumb--default-font
    // TODO was lighten($breadcrumb-font-color, 20)
    color: #666
    list-style-type: none

  &.-show
    display: block


